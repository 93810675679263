/*
 * @Author: iKun
 * @Date: 2023-07-01 18:31:46
 * @LastEditors: iKun
 * @LastEditTime: 2024-02-19 09:38:30
 * @Description: 介绍文件的作用
 */
import axios from "axios";
import router from "../router/index";
import serveMap from "./serveMap";
import standardData from "@/config/standardData";
import { getCookieValue, setCookieValue } from "@/utils/auth";
import { warnMsg, setLoading } from "@/utils/comElement";

const hintMap = standardData.hintMap;
const language = getCookieValue("LANGUAGE");
let loading = null;

const service = axios.create({
  timeout: 600000,
  "Content-Type": "application/json;charset=utf-8",
  baseURL:
    process.env.NODE_ENV == "development" ? process.env.VUE_APP_BASE_API : "",
});

service.interceptors.request.use(
  (config) => {
    loading = setLoading("处理中...");
    const token = getCookieValue("TOKEN");
    config.headers["Authorization"] = token ? "Bearer " + token : "";
    return config;
  },
  (error) => {
    if (loading) {
      loading.close();
    }
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    loading.close();
    if (response.data.code === 200) {
      if (response.data.data) {
        return response.data.data;
      } else {
        return response.data;
      }
    } else if (response.data.size) {
      return response.data;
    } else if (response.data.code === 444) {
      warnMsg(hintMap.tokenExpire[language]);
      setCookieValue("ROLE", "");
      setCookieValue("USER_ID", "");
      setCookieValue("USER_NAME", "");
      setCookieValue("TOKEN", "");
      setCookieValue("PROF_NAME", "");
      const timer = setTimeout(() => {
        router.push("/login");
        clearTimeout(timer);
      }, 3000);
      return Promise.reject(response.data);
    } else {
      warnMsg(response.data.msg || hintMap.resErr[language]);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    loading.close();
    return Promise.reject(error);
  }
);

const sendRequest = (url, params, method = "post", responseType = "json") => {
  if (!Object.prototype.hasOwnProperty.call(serveMap, url)) {
    const message = "服务[ " + url + " ]有误，请查看!";
    return warnMsg(message);
  }
  const sendObj = {};
  sendObj.method = method;
  if (params && params.pageSize) {
    sendObj.url =
      serveMap[url] +
      "?pageSize=" +
      params.pageSize +
      "&pageNum=" +
      params.pageNum;
  } else {
    sendObj.url = serveMap[url];
  }
  if (params && method === "post") {
    if (sendObj.url.indexOf("delDepth") > -1) {
      sendObj.url += `/${params.id}`;
    } else {
      sendObj.data = params;
    }
  }
  if (params && method === "get") {
    sendObj.params = params;
  }

  sendObj.responseType = responseType;

  return service(sendObj);
};

export default { sendRequest };
