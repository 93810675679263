/*
 * @Description: 
 * @Version: V1.0.0
 * @Author: iKun
 * @Date: 2023-07-17 19:29:33
 * @LastEditors: iKun
 * @LastEditTime: 2023-10-08 20:00:46
 * @FilePath: main.js
 * Copyright 2023 Marvin, All Rights Reserved. 
 * 2023-07-17 19:29:33
 */
import Vue from 'vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem.js'

import './styles/common.scss'
import './styles/reset.scss'

Vue.use(ElementUI, { locale, size: 'small' })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
