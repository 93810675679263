const common = {
	state: {
		language: 'en'
	},
	mutations: {
		SET_LANGUAGE (state, data) {
			state.language = data
		}
	},
	actions: {
		Set_Language ({ commit }, data) {
			commit('SET_LANGUAGE', data)
		}
	},
	getters: {
		language: state => state.language
	}
}
export default common
