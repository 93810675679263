/*
 * @Author: iKun
 * @Date: 2023-06-06 10:50:00
 * @LastEditors: iKun
 * @LastEditTime: 2024-01-04 15:55:49
 * @Description: 介绍文件的作用
 */
import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "@/views/home/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home-page",
    component: HomePage,
    children: [
      {
        path: "/",
        name: "home-page",
        component: () =>
          import(
            /* webpackChunkName: "user-info" */ "@/views/home/components/home-page.vue"
          ),
      },
      {
        path: "/home/userInfo",
        name: "user-info",
        component: () =>
          import(
            /* webpackChunkName: "user-info" */ "@/views/userInfo/user-info.vue"
          ),
      },{
        path: '/home/introduce',
        name: 'introduce',
        component: () => import('@/views/introduce/index.vue')
      }
    ],
  },
  {
    path: "/register",
    name: "register-page",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/register/index.vue"),
  },
  {
    path: "/login",
    name: "login-page",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/index.vue"),
  },
  {
    path: "/tour",
    name: "tour-page",
    component: () =>
      import(/* webpackChunkName: "tour-page */ "@/views/tour/index.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
