<template>
  <!-- 头部 -->
  <div class="home-header">
    <div class="home-header-lf">
      <div class="home-header-logo" @click="toHome">VSyncLab</div>
    </div>
    <div class="home-header-ct">
      <el-dropdown
        class="menu-list"
        placement="bottom"
        v-for="item in menuList"
        :key="item.id"
        v-if="showMothod(item.notLoginShow)"
      >
        <span
          :class="[item.menuClick ? 'button-point' : '', 'menu-item']"
          @click="jumpPage(item.id, item.needLogin)"
          >{{ item[language] }}
          <span v-if="item.labelText" class="menu-label">{{
            item.labelText
          }}</span
          ><i v-if="item.showIcondown" class="el-icon-arrow-down"></i
        ></span>
        <template #dropdown>
          <el-dropdown-menu class="custom-dropdown">
            <el-dropdown-item
              class="custom-dropdown-item"
              v-for="menu in item.children"
              :key="menu.id"
              v-if="!menu.needLoginShow"
            >
              <span @click="jumpPage(menu.id, menu.needLogin)">{{
                menu[language]
              }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="home-header-rt">
      <div class="nav-btn" v-show="username" @click="invite">
        {{ btnTextMap.invite[language] }}
      </div>
      <div class="nav-btn" v-show="!username" @click="login">
        {{ btnTextMap.login[language] }}
      </div>
      <div class="nav-btn" v-show="!username" @click="register">
        {{ btnTextMap.register[language] }}
      </div>
      <!-- <el-button v-show="username" @click="invite">{{
        btnTextMap.invite[language]
      }}</el-button>
      <el-button v-show="!username" @click="login">{{
        btnTextMap.login[language]
      }}</el-button>
      <el-button v-show="!username" @click="register">{{
        btnTextMap.register[language]
      }}</el-button> -->
      <el-select
        v-model="localLanguage"
        @change="changeLanguage"
        class="reset-select language-select"
      >
        <el-option
          v-for="(key, val) in languages"
          :key="key"
          :value="val"
          :label="key"
        ></el-option>
      </el-select>
      <span class="user-name" v-show="username">
        <div class="user-name-icon"></div>
        <div class="user-name-content" @click="toUserInfo">
          {{ username }}
        </div>
      </span>
    </div>
    <invite-win ref="inviteWin" :language="language"></invite-win>
  </div>
</template>

<script>
import { getCookieValue } from "@/utils/auth";
import { warnMsg } from "@/utils/comElement";
import standardData from "@/config/standardData";
import InviteWin from "@/views/invite/index";

export default {
  name: "home-header",
  components: {
    InviteWin,
  },
  props: {
    language: {
      type: String,
      required: true,
    },
    toHomeAble: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    username() {
      return getCookieValue("USER_NAME");
    },
  },
  watch: {
    language(val) {
      this.localLanguage = val;
    },
  },
  data() {
    return {
      menuList: standardData.menuList,
      languages: standardData.languages,
      hintMap: standardData.hintMap,
      btnTextMap: standardData.btnTextMap,
      localLanguage: this.language,
    };
  },
  methods: {
    toHome() {
      // if (this.toHomeAble) {
      this.$router.push("/");
      // }
    },
    toUserInfo() {
      this.$router.push("/home/userInfo");
    },
    changeLanguage(val) {
      this.$emit("changeLanguage", val);
    },
    invite() {
      this.$refs.inviteWin.openWin();
    },
    register() {
      this.$router.push("/register");
    },
    login() {
      this.$router.push("/login");
    },
    showMothod(notLoginShow) {
      if (notLoginShow && !this.username) {
        return true;
      }
      if (notLoginShow && this.username) {
        return false;
      }
      return true;
    },
    jumpPage(id, needLogin) {
      if (!this.username && needLogin) {
        warnMsg(this.hintMap.notLogin[this.language]);
        setTimeout(() => {
          this.login();
        }, 2000);
      } else {
        if (
          [
            "virtualLab",
            "inventory",
            "messageCenter",
            "imgCheck",
            "periodical",
          ].includes(id)
        ) {
          this.$router.push(`/tour?id=${id}`);
        } else {
          this.$router.push(`/home/introduce?id=${id}`);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-header {
  width: 100%;
  height: 80px;
  padding: 0 310px;
  line-height: 80px;
  background: linear-gradient(270deg, #9eaab7 0%, #748192 100%);
}

.home-header-lf {
  float: left;
  line-height: 80px;
  margin-right: 50px;
}

.home-header-logo {
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  font-family: "AlibabaPuHuiTi_3_95_ExtraBold";
}

.home-header-ct {
  float: left;
  color: #fff;
  margin-left: 20px;
  font-family: "AlibabaPuHuiTi_3_55_Regular";
}

.home-header-rt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menu-list {
  margin: 0 30px;
}

.el-select {
  width: 90px;
  margin-left: 10px;
  border: none;
}

.user-name {
  cursor: pointer;
  color: #666;
  display: inline-block;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &-icon {
    width: 60px;
    height: 60px;
    background-image: url("~@/assets/images/head.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
  }
  &-content {
    font-size: 20px;
    font-family: AlibabaPuHuiTi_3_55_Regular;
    color: #ffffff;
    line-height: 28px;
  }
}
.button-point {
  cursor: pointer;
}
.menu-item {
  position: relative;
}
.menu-label {
  position: absolute;
  right: -10px;
  top: -20px;
  background: #ff0000;
  color: #fff;
  padding: 0 2px;
  border-radius: 5px;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
}
</style>
