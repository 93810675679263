/*
 * @Author: iKun
 * @Date: 2023-05-21 22:51:36
 * @LastEditors: iKun
 * @LastEditTime: 2023-07-27 10:44:49
 * @Description: 弹窗
 */
import { Loading, Message, MessageBox } from 'element-ui'
import standardData from '@/config/standardData'
import { getCookieValue } from './auth'
const message = (type, text, duration) => {
  Message({
    type: type,
    message: text,
    duration: duration
  })
}
const language = getCookieValue('LANGUAGE')
const confirmButtonText = standardData.btnTextMap.confirm[language]
const cancelButtonText = standardData.btnTextMap.cancel[language]

export const warnMsg = (text = '操作失败！', duration = 3000) => {
  message('warning', text, duration)
}

export const succMsg = (text = '操作成功！', duration = 3000) => {
  message('success', text, duration)
}

//显示loading
export const setLoading = text => {
	return Loading.service({
		lock: true,
		text: text || '处理中...',
		background: 'rgba(0, 0, 0, 0.7)'
	})
}

//提示信息
export const alertMsg = (text, title, type, callback) => {
	MessageBox.alert(text, title, {
		type: type,
		showClose: false,
		callback: callback,
		confirmButtonText
	})
}

//提示信息
export const confirmMsg = (text, title, type, succCallback, errCallback,confirmText = confirmButtonText,cancelText = cancelButtonText) => {
	MessageBox.confirm(text, title, {
		type: type,
		confirmButtonText: confirmText,
		cancelButtonText: cancelText,
	}).then(() => {
		succCallback && succCallback()
	}).catch(() => {
		errCallback && errCallback()
	})
}
//取消按钮有事件的提示信息
export const cancelFuncConfirmMsg = (text, title, type, succCallback, errCallback,confirmText = confirmButtonText,cancelText = cancelButtonText) => {
	MessageBox.confirm(text, title, {
		type: type,
		confirmButtonText: confirmText,
		cancelButtonText: cancelText,
		distinguishCancelAndClose: true,
	}).then(() => {
		succCallback && succCallback()
	}).catch((action) => {
		if(action === 'close') return
		errCallback && errCallback()
	})
}
