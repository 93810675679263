<template>
  <!-- 邀请 -->
  <el-dialog
    width="600px"
    :show-close="false"
    :close-on-click-modal="false"
    :title="titleMap.invite[language]"
    :visible.sync="dialogVisible"
  >
    <div v-if="username">
      <div class="invite-title" v-if="language === 'en'">
        {{ `Invite members to join ${profName}'s Lab` }}
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="addMember"
        ></el-button>
      </div>
      <div class="invite-title" v-else>
        {{ `邀请组员加入 ${profName}'s Lab` }}
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="addMember"
        ></el-button>
      </div>
    </div>
    <el-table v-if="username" border size="mini" :data="members">
      <el-table-column show-overflow-tooltip
        align="center"
        prop="email"
        :label="labelMap.email[language]"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.email"
            :placeholder="hintMap.memberEmail[language]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
        align="center"
        width="200px"
        prop="role"
        :label="labelMap.role[language]"
      >
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.role"
            :placeholder="hintMap.role[language]"
          >
            <el-option
              v-for="item in roles"
              :key="item.value"
              :value="item.value"
              :label="item[language]"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
        align="center"
        width="80px"
        :label="labelMap.handle[language]"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="deleteMember(scope.$index)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="invite-title" v-if="language === 'en'">
      <span>Invite friends to register VSyncLab</span>
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="addFriend"
      ></el-button>
    </div>
    <div class="invite-title" v-else>
      <span>邀请朋友注册 VSyncLab</span>
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="addFriend"
      ></el-button>
    </div>
    <el-table border size="mini" :data="friends">
      <el-table-column show-overflow-tooltip
        align="center"
        prop="email"
        :label="labelMap.email[language]"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.email"
            :placeholder="hintMap.friendEmail[language]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
        align="center"
        width="80px"
        :label="labelMap.handle[language]"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="deleteFriend(scope.$index)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer">
      <el-button @click="closeWin">{{ btnTextMap.cancel[language] }}</el-button>
      <el-button type="primary" @click="submitForm">{{
        btnTextMap.submit[language]
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from "@/api/request";
import { getCookieValue } from "@/utils/auth";
import standardData from "@/config/standardData";
import { warnMsg, succMsg } from "@/utils/comElement";

export default {
  props: {
    language: {
      type: String,
      required: true,
    },
  },
  computed: {
    profName() {
      return getCookieValue("PROF_NAME");
    },
    username() {
      return getCookieValue('USER_NAME')
    },
  },
  data() {
    return {
      dialogVisible: false,
      roles: standardData.roles,
      hintMap: standardData.hintMap,
      titleMap: standardData.titleMap,
      labelMap: standardData.labelMap,
      btnTextMap: standardData.btnTextMap,
      members: [],
      friends: [],
    };
  },
  methods: {
    addMember() {
      this.members.push({
        email: "",
        role: "",
        language: this.language,
      });
    },
    deleteMember(index) {
      this.members.splice(index, 1);
    },
    addFriend() {
      this.friends.push({
        email: "",
        language: this.language,
      });
    },
    deleteFriend(index) {
      this.friends.splice(index, 1);
    },
    submitForm() {
      if (!this.members.length && !this.friends.length) {
        return warnMsg(this.hintMap.emptyData[this.language]);
      }
      let isEmpty = false;
      let formatTrue = true;
      const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      this.members.forEach((item) => {
        if (!item.email || !item.role) {
          isEmpty = true;
        } else if (!reg.test(item.email)) {
          formatTrue = false;
        }
      });
      this.friends.forEach((item) => {
        if (!item.email) {
          isEmpty = true;
        } else if (!reg.test(item.email)) {
          formatTrue = false;
        }
      });
      if (isEmpty) {
        return warnMsg(this.hintMap.emptyInfo[this.language]);
      }
      if (!formatTrue) {
        return warnMsg(this.hintMap.emailErr[this.language]);
      }
      const params = {
        inviteList: [...this.members, ...this.friends],
      };
      api.sendRequest("INVITE_REGISTER", params).then(() => {
        succMsg(this.hintMap.inviteSucc[this.language]);
        this.closeWin();
      });
    },
    openWin() {
      this.dialogVisible = true;
    },
    closeWin() {
      this.dialogVisible = false;
      this.members = [];
      this.friends = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.invite-title {
  font-size: 14px;
  .el-button--mini {
    padding: 5px;
    margin-left: 5px;
  }
}
.el-table {
  margin: 10px 0 20px 0;
  .el-button {
    padding: 5px;
  }
}
</style>