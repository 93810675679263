import Cookie from 'js-cookie'

const keyMap = {
  ROLE: 'role',
  USER_ID: 'user_id',
  USER_NAME: 'user_name',
  TOKEN: 'synclab_token',
  PROF_NAME: 'prof_name',
  LANGUAGE: 'language',
  SELECT_POSITION: 'select_position',
}

export function setCookieValue(key, value) {
  return Cookie.set(keyMap[key], value)
}

export function getCookieValue(key) {
  return Cookie.get(keyMap[key])
}

export function removeCookieValue(key) {
  return Cookie.remove(keyMap[key])
}