<template>
  <!-- 尾部 -->
  <div class="home-footer">
    <div class="home-footer-icon">
      <img src="@/assets/images/home/item-7.png" alt="" />
    </div>
    <div class="footer-wrap">
      <div class="home-footer-lf">
        <div class="footer-logo">Contact Us</div>
        <div class="info-list" v-for="(item, index) in infoList" :key="index">
          <img :src="require('../../assets/icons/' + item.img + '.png')" />
          <span>{{ item.text }}</span>
        </div>
      </div>
      <div class="home-footer-rt">
        <div class="footer-list" v-for="item in footerList" :key="item.id">
          <div class="footer-list-title">{{ item[language] }}</div>
          <div
            class="footer-list-item"
            v-for="val in item.children"
            :key="val.id"
            @click="jumpPage(val.id, val.needLogin)"
          >
            {{ val[language] }}
          </div>
        </div>
      </div>
    </div>
    <div class="info">
      <div style="">
        <a
          target="_blank"
          href="https://beian.miit.gov.cn"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img
            src="https://www.beian.gov.cn/img/new/gongan.png"
            style="float: left"
          />
          <p>苏ICP备2023023980号-1</p></a
        >
      </div>
      <div style="">
        <a
          target="_blank"
          href="https://beian.miit.gov.cn"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img
            src="https://www.beian.gov.cn/img/new/gongan.png"
            style="float: left"
          />
          <p>苏公网安备32011302321566号</p></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import standardData from '@/config/standardData'
import { warnMsg } from '@/utils/comElement'
export default {
  name: 'home-footer',
  props: {
    language: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      footerList: standardData.footerList,
      infoList: standardData.infoList,
    }
  },
  methods: {
    jumpPage(id, needLogin) {
      if (!this.username && needLogin) {
        warnMsg(this.hintMap.notLogin[this.language])
        setTimeout(() => {
          this.login()
        }, 2000)
      } else {
        if (
          ['virtualLab', 'inventory', 'messageCenter', 'imgCheck'].includes(id)
        ) {
          this.$router.push(`/tour?id=${id}`)
        } else {
          this.$router.push(`/home/introduce?id=${id}`)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.home-footer {
  color: #666;
  margin: auto;
  font-size: 14px;
  padding: 20px 0;
  line-height: 30px;
  width: 100%;
  padding: 0 460px;
  font-family: AlibabaPuHuiTi_3_55_Regular;

  color: #d2dbe3;
  background: linear-gradient(270deg, #8996a4 0%, #6a7888 100%);
}
.home-footer-icon {
  width: 237px;
  // margin-left: 105px;
  padding: 47px 0;
  img {
    width: 100%;
  }
}
.footer-wrap {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.footer-logo {
  font-size: 20px;
  font-family: AlibabaPuHuiTi_3_65_Medium;
  color: #ffffff;
  line-height: 60px;
}

.home-footer-rt {
  display: flex;
  align-content: space-around;
}

.footer-list {
  margin-left: 50px;
  line-height: 60px;
}

.footer-list-title {
  font-size: 20px;
  font-family: AlibabaPuHuiTi_3_65_Medium;
  color: #ffffff;
}

.footer-list-item {
  cursor: pointer;
}

.footer-list-item:hover {
  color: #2baae1;
}

.info-list {
  line-height: 60px;
  img {
    vertical-align: middle;
    margin-right: 10px;
    width: 14px;
  }
}
.info {
  color: #fff;
  margin: 0 auto;
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    margin: 0 10px;
  }
  p {
    float: left;
    height: 20px;
    line-height: 20px;
    margin: 0px 0px 0px 5px;
    color: #d2dbe3;
  }
}
</style>
