export default {
	languages: {
		en: 'English',
		zh: '中文'
	},
	slogenTitle: {
		en: 'Your Lab, at Your Fingertips!',
		zh: '实验室，尽在你的指尖！'
	},
	currencys: [
		{ value: '¥' },
		{ value: '$' },
		{ value: 'CNY' }
	],
	roles: [
		{ value: 'admin', en: 'admin', zh: '管理员' },
		{ value: 'member', en: 'member', zh: '组员' }
	],
	identitys: [
		{ value: 'prof', en: 'prof', zh: '教授' },
		{ value: 'leader', en: 'leader', zh: '组长' },
		{ value: 'member', en: 'member', zh: '组员' }
	],
	memberStatus: [
		{ en: 'Valid', zh: '有效' },
		{ en: 'Invalid', zh: '无效' },
		{},
	],
	menuList: [
		{
			id: 'tour', en: 'TOUR', zh: '导览', children: [
				{ id: 'overview',en: 'Overview',zh:'简介'},
				{ id: 'tutorial',en: 'Tutorial',zh:'使用说明'},
				{ id: 'virtualLab', en: 'Virtual Lab', zh: '虚拟实验室',needLogin: true },
				{ id: 'inventory', en: 'Inventory', zh: '仓库',needLogin: true },
				{ id: 'messageCenter', en: 'Message Center', zh: '消息中心',needLogin: true },
				{ id: 'imgCheck',en: 'IMGcheck',zh:'图片查重',needLogin: true},
				{ id: 'periodical',en: 'Bounty',zh:'悬赏活动',needLogin: true},
				// { id: 'databasePortal', en: 'Database Portal', zh: '数据库门户' },
				// { id: 'taskCrowdsourcing', en: 'Task Crowdsourcing', zh: '任务众包' }
			],
			showIcondown: true,
			labelText: ''
		},
		{
			id: 'suppliers', en: 'SUPPLIERS', zh: '供应商(暂未开放)', children: [
				{ id: 'supplierPartners', en: 'Supplier Partners', zh: '合作' },
				{ id: 'becomeASupplier', en: 'Become a Supplier', zh: '成为供应商' }
			],
			showIcondown: true,
			labelText: ''
		},
		{
			id: 'aboutUs', en: 'ABOUT US', zh: '关于我们', children: [
				{ id: 'aboutSynclab', en: 'About VSyncLab', zh: '关于VSyncLab' },
				// { id: 'joinUs', en: 'Join Us', zh: '加入我们' }
			],
			showIcondown: true,
			labelText: ''
		},
		{ id: 'imgCheck',en: 'IMGcheck',zh:'图片查重',needLogin: false,notLoginShow: true, showIcondown: false, menuClick:true,
		labelText: 'hot' },
	],
	imageTaskStatus: {
		1: {zh:'新建',en:'New'},
		2: {zh:'处理中',en:'Processing'},
		3: {zh:'已完成',en:'Completed'},
		4: {zh:'已失败',en:'Failed'},
	},
	footerList: [
		{
			id: 'learnAboutUs', en: 'LEARN ABOUT Us', zh: '了解我们', children: [
				{ id: 'aboutSynclab', en: 'About VSyncLab', zh: '关于VSyncLab' },
				{ id: 'becomeASupplier', en: 'Become a Supplier', zh: '成为供应商' },
				{ id: 'tutorial', en: 'Tour', zh: '导览' },
				{ id: 'supplierPartners', en: 'Partners', zh: '供应商伙伴' }
			]
		},
		{
			id: 'suppprt', en: 'SUPPORT', zh: '支持', children: [
				{ id: 'faq', en: 'FAQ', zh: 'FAQ' },
				{ id: 'videoTour', en: 'Video Tour', zh: '视频导览' },
				{ id: 'reportBug', en: 'Report a Bug', zh: '反馈Bug' },
				{ id: 'makeSuggestion', en: 'Make a Suggestion', zh: '建议/意见' }
			]
		},
		{
			id: 'legal', en: 'LEGAL', zh: '法律', children: [
				{ id: 'termsService', en: 'Terms of Service', zh: '服务条款' },
				{ id: 'privacyPolicy', en: 'Privacy Policy', zh: '隐私政策' },
				{ id: 'securityPractices', en: 'Security Practices', zh: '安全实践' }
			]
		}
	],
	infoList: [
		{ text: 'vsynclab@outlook.com', img: 'email' },
		{ text: '17712865753', img: 'phone' },
		{ text: '1781342426', img: 'qq' },
		{ text: 'VSyncLab', img: 'wx' }
	],
	tabList: [
		{ id: 'virtualLab', en: 'Virtual Lab', zh: '虚拟实验室', needLogin: true},
		{ id: 'inventory', en: 'Inventory', zh: '仓库', needLogin: true},
		{ id: 'messageCenter', en: 'Message Center', zh: '消息中心', needLogin: true},
		{ id: 'imgCheck',en: 'IMGcheck',zh:'图片查重', needLogin: false}, 
		{ id: 'periodical',en: 'Bounty',zh:'悬赏活动',needLogin: true},
		// { id: 'dbPortal', en: 'DB Portal', zh: '数据库门户' },
		// { id: 'tasks', en: 'Tasks', zh: '任务众包' }
	],
	bountyTabs: [{id: 'journal',en: 'journal list',zh: '期刊列表'},{id: 'artical',en: 'DB(p)ret',zh: '问题文章数据库'}],
	messageStatus: [
		{ value: '', en: 'All', zh: '全部' },
		{ value: 0, en: 'Unread', zh: '未读' },
		{ value: 1, en: 'Read', zh: '已读' }
	],
	messageStatusTitle: [
		{ value: '', en: 'All Information', zh: '全部消息' },
		{ value: 0, en: 'Unread Information', zh: '未读消息' },
		{ value: 1, en: 'Read Information', zh: '已读消息' }
	],
	labelMap: {
		fullName: { en: 'Full Name', zh: '姓名' },
		email: { en: 'Email', zh: '邮箱' },
		verifyCode: { en: 'Verify Code', zh: '验证码' },
		password: { en: 'Password', zh: '密码' },
		verifyPassword: { en: 'Password2', zh: '确认密码' },
		affilication: { en: 'Affiliation', zh: '单位名称' },
		identity: { en: 'Identity', zh: '身份' },
		profName: { en: 'Lab Name', zh: '实验室名称' },
		location: { en: 'Location', zh: '工作地点' },
		groupMember: { en: 'Group Member', zh: '组员' },
		size: { en: 'Size', zh: '规模' },
		remark: { en: 'Remarks', zh: '备注' },
		role: { en: 'Role', zh: '角色' },
		handle: { en: 'Handle', zh: '操作' },
		containerName: { en: 'Name', zh: '名称' },
		containerType: { en: 'Template', zh: '容器模板' },
		imgUrl: { en: 'Image', zh: '容器照片' },
		rows: { en: 'Rows', zh: '行' },
		cols: { en: 'Cols', zh: '列' },
		sort: { en: 'Sort', zh: '排序' },
		searchType: { en: 'Search type', zh: '搜索类型' },
		allItems: { en: 'Search all items', zh: '搜索所有物品' },
		specificItem: { en: 'Search for a specific item', zh: '搜索指定物品' },
		details: { en: 'Details', zh: '详情' },
		itemLink: { en: 'Item Link', zh: '物品链接' },
		productName: { en: 'Item Name', zh: '物品名称' },
		company: { en: 'Vendor', zh: '供应商' },
		itemId: { en: 'Catalog No.', zh: '货号' },
		packageSize: { en: 'Unit Size', zh: '单位规格' },
		price: { en: 'Unit Price', zh: '单价' },
		quantity: { en: 'Quantity', zh: '数量' },
		priceType: { en: 'Currency', zh: '币种' },
		type: { en: 'Type', zh: '种类' },
		inviteCode: {en:'Invite Code', zh:'邀请码'},
		grantId: { en: 'Grant ID', zh: '基金编号' },
		productUrl: { en: 'Item Link', zh: '物品链接' },
		position: { en: 'Location', zh: '容器坐标' },
		salesRepresentative: { en: 'Sales Call', zh: '销售电话' },
		totalPrice: { en: 'Total Price', zh: '总价' },
		notes: { en: 'Notes', zh: '备注' },
		createBy: { en: 'Creator', zh: '创建人' },
		createTime: { en: 'Create Date', zh: '创建日期' },
		startDate: { en: 'Start Date', zh: '开始日期' },
		endDate: { en: 'End Date', zh: '结束日期' },
		topId: { en: 'Container', zh: '容器' },
		fuzzySearch: { en: 'Fuzzy Search', zh: '模糊搜索' },
		edit: { en: 'Edit', zh: '编辑' },
		kickOut: { en: 'Kick Out', zh: '踢出' },
		editRole: { en: 'Edit Role', zh: '编辑角色' },
		classification: { en: 'Classification', zh: '消息类型' },
		content: { en: 'Content', zh: '消息内容' },
		date: { en: 'Date', zh: '日期' },
		time: { en: 'Time', zh: '时间' },
		status: { en: 'Status', zh: '状态' },
		nameFilter: { en: 'Name Filters', zh: '容器选择' },
		filter: { en: 'Filter', zh: "筛选" },
		updateTime: {en:'Update Time', zh:'更新时间'},
		total: {en:'Total', zh:'总数'},
	},
	hintMap: {
		required: { en: 'Required', zh: '必填' },
		enter: { en: 'Please enter', zh: '请输入' },
		select: { en: 'Please select', zh: '请选择' },
		notOrder: { en: 'You do not have permission', zh: '您没有权限' },
		notEditItem: { en: 'Items that have been ordered or stored cannot be edited', zh: '已下单或入库的物品不能编辑' },
		receiveSelf: { en: 'You can only add items to the warehouse yourself', zh: '您只能将自己添加的物品入库' },
		editSelf: { en: 'You can only edit items you add yourself', zh: '您只能编辑自己添加的物品' },
		deleteSelf: { en: 'You can only delete items that you have added', zh: '您只能删除自己添加的物品' },
		containerName: { en: 'Please enter a container name', zh: '请输入容器名称' },
		containerType: { en: 'Select a container template', zh: '请选择容器模板' },
		imgUrl: { en: 'Please upload the container picture', zh: '请上传容器照片' },
		rows: { en: 'Please enter the number of container rows', zh: '请输入容器行数' },
		cols: { en: 'Please enter the number of container columns', zh: '请输入容器列数' },
		sort: { en: 'Please enter the container sort number', zh: '请输入容器排序数' },
		fullName: { en: 'Please enter your full name', zh: '请输入您的姓名' },
		email: { en: 'The mailbox format is incorrect', zh: '邮箱格式不正确'  },
		memberEmail: { en: 'Please enter the member email address', zh: '请输入组员邮箱' },
		friendEmail: { en: "Please enter your friend's email address", zh: '请输入朋友邮箱' },
		role: { en: 'Please select a role', zh: '请选择角色' },
		groupMember: { en: 'Please select a group member', zh: '请选择组员' },
		verifyCode: { en: 'Please enter the verification code you received in your email', zh: '请输入您邮箱收到的验证码' },
		password: { en: 'Please enter your password', zh: '请输入您的密码' },
		verifyPassword: { en: 'Please enter your password again', zh: '请再次输入密码' },
		affilication: { en: 'Please enter the name of your university, institute or company', zh: '请输入您的大学、研究所或公司名称' },
		identity: { en: 'Please select your identity', zh: '请选择您的身份' },
		profName: { en: "Please enter your laboratory name", zh: '请输入您的实验室名称' },
		location: { en: 'Please enter the country, province, city and district where you work', zh: '请输入您工作所在的国家、省、市和区' },
		size: { en: 'Please enter the number of people in your laboratory', zh: '请输入您实验室的人数' },
		remark: { en: 'Please enter your remarks', zh: '请输入您的备注信息' },
		emailErr: { en: 'The mailbox format is incorrect', zh: '邮箱格式不正确' },
		psdErr: { en: 'The password contains 6 to 12 letters and digits', zh: '密码长度为6 ~ 12位，由字母和数字组成' },
		confirmPsdErr: { en: 'Confirm that the password is inconsistent with the password', zh: '确认密码与密码不一致' },
		sendSucc: { en: 'Send successfully, please open your email to check the verification code', zh: '发送成功，请打开您的邮箱查看验证码' },
		registerSucc: { en: 'Congratulations on your successful registration, will jump to the login page', zh: '恭喜您注册成功，即将跳转登录页面' },
		loginSucc: { en: 'Login successful', zh: '登录成功' },
		resetSucc: { en: 'Password reset succeeded', zh: '密码重置成功' },
		inviteSucc: { en: 'Successful invitation', zh: '邀请成功' },
		resSucc: { en: 'Operate successfully', zh: '操作成功' },
		resErr: { en: 'Operation failure', zh: '操作失败' },
		notLogin: { en: 'Please login first', zh: '请先登录' },
		noContainer: { en: 'No container instance is available', zh: '暂无容器实例' },
		tokenExpire: { en: 'Login expired, please login again', zh: '登录过期，请重新登录' },
		emptyData: { en: 'Add at least one piece of data', zh: '请至少添加一条数据' },
		emptyInfo: { en: 'Please fill in the information completely', zh: '请将信息填写完整' },
		unselected: { en: 'Please select a container first', zh: '请先选择一个容器' },
		confirmDel: { en: 'Are you sure to delete?', zh: '确定删除？' },
		confirmLogOff: { en: 'Are you sure to log off?', zh: '确定注销？' },
		selectItem: { en: 'Please select an item data', zh: '请选择一条物品数据' },
		allItems: { en: 'Please enter item name, vendor or catalog number', zh: '请输入物品名、供应商或货号' },
		company: { en: 'Please select vendor', zh: '请选择供应商' },
		itemId: { en: 'Please enter the catalog number', zh: '请输入货号' },
		productName: { en: 'Please enter or select item', zh: '请输入或选择物品' },
		emptyUrl: { en: 'Please enter item link', zh: '请输入物品链接' },
		price: { en: 'Please enter unit price', zh: '请输入单价' },
		quantity: { en: 'Please enter the purchase quantity', zh: '请输入购买数量' },
		totalPrice: { en: 'Please enter the total price', zh: '请输入总价' },
		reconfirm: { en: 'Are you sure to add this item to the warehouse?', zh: '确定将该物品加入仓库？' },
		deleteItem: { en: 'Are you sure you want to delete it?', zh: '确定要删除？' },
		kickOut: { en: 'Are you sure you want to kick out it?', zh: '确定要踢出？' },
		noDelTemp: { en: 'Container template to which container instances have been added cannot be deleted', zh: '已添加容器实例的容器模板不能被删除' },
		usedPosition: { en: 'Please select an unoccupied locations', zh: '请选择未被占用的位置' },
		noPosition: { en: 'Please select at least one location', zh: '请至少选择一个位置' },
		onePosition: { en: 'Please select a location', zh: '请选择一个位置' },
		usedPst: { en: 'Please select at least one occupied location', zh: '请至少选择一个被占用的位置' },
		twoPst: { en: 'Please select two drawers', zh: '请选择两个架子' },
		twoUsedPst: { en: 'Please choose at least one drawer with a box', zh: '请至少选择一个放了盒子的架子' },
		twoBox: { en: 'Please select two boxes', zh: '请选择两个盒子' },
		twoUsedBox: { en: 'Please select at least one location with a box', zh: '请至少选择一个有盒子的位置' },
		orderNoLocation: { en: 'We can\'t locate the product in Virtual Laboratory', zh: '无法在虚拟实验室中找到产品' },
		moreThan12: { en: 'Please input positive integer less than or equal 12', zh: '请输入小于等于12的正整数' },
		taskReminder: {en: 'Your Images and results will be cleared after 24 hours, please check and download this report', zh: '您的图片和结果将在24小时后清除，请及时查看并下载本次报告'},
		downloadTips: {en:'Please check the full results by downloading the report below',zh:'请下载报告查看完整结果'},
		moreThan2Pic: {en:'Please upload at least two pictures',zh:'请至少上传两张图片'},
		taskTips1: {en: 'You could upload flow cytometry dot plots, photomicrographs about tissue section and staining, immunofluorescence (IF), immunohistochemistry (IHC), cultured cell, C. elegans etc.',zh: '支持流式细胞dot plot、组织切片及染色、免疫荧光、免疫组化、细胞等多种类图片；'},
		taskTips2: {en: 'Please upload 2-10 images for IMGchecking.',zh: '请上传2-10张图片用于比对；'},
		taskTips3: {en: 'We could not provide thumbnail for image in tif format, please submit the task when the upload was finished.',zh: 'tif格式图片无法显示缩略图，可在图片上传成功后直接提交任务。'},
		taskTips4:{en: 'IMGcheck will expand its services to include flow cytometry dot plots in the upcoming version.',zh:'我们将会在接下来的版本中增加对流式细胞dot plot的支持。'},
		uploadTips: {en: 'Please upload the image in jpg, jpeg, png, tif or tiff format',zh: '请上传jpg、jpeg、png、tif或tiff格式的图片'},
		reuploadTips: {en: 'Please don\'t upload the same image more than once',zh:'请勿重复上传同一张图片'},
		uploadWaitingTips: {en: 'Please waiting picture upload complete...',zh:'图片上传中，请稍后...'},
		inviteCode: { en: 'Please enter the invitation code', zh: '请输入邀请码' },
		imageCheckLoginTips1: {en: 'Your IMGchecked-results will be accessed by all users, please ',zh:'当前结果将对所有使用者开放，<strong>私密查重</strong>可'},
		imageCheckLoginTips2: {en: ' to keep your results <strong>confidential</strong>.',zh:'使用。'},
		imageCheckLoginTips3: {en: '<strong>login</strong>',zh:'<strong>注册登录</strong>'},
		enterDOI: {en: 'Please enter DOI or PMID',zh:'请输入DOI或者PMID'},
		enterJournal: {en: 'Please enter journal',zh:'请输入期刊名进行检索'},
	},
	titleMap: {
		register: { en: 'Get started for free', zh: '免费使用' },
		login: { en: 'Login to VSyncLab', zh: '登录 VSyncLab' },
		resetPsd: { en: 'Reset Password', zh: '重置密码' },
		invite: { en: 'Invite to join', zh: '邀请加入' },
		reconfirm: { en: 'Reconfirm', zh: '再次确认' },
		template: { en: 'Container Template', zh: '容器模板' },
		addTemplate: { en: 'Add Container Template', zh: '新增容器模板' },
		editTemplate: { en: 'Edit Container Template', zh: '编辑容器模板' },
		viewTemplate: { en: 'View Container Template', zh: '查看容器模板' },
		container: { en: 'Container', zh: '容器' },
		addContainer: { en: 'Add Container', zh: '新增容器' },
		editContainer: { en: 'Edit Container', zh: '编辑容器' },
		viewContainer: { en: 'View Container', zh: '查看容器' },
		configContainer: { en: 'Config Container', zh: '配置容器' },
		addDrawer: { en: 'Add Drawer', zh: '新增架子' },
		editDrawer: { en: 'Edit Drawer', zh: '编辑架子' },
		addBox: { en: 'Add Box', zh: '新增盒子' },
		editBox: { en: 'Edit Box', zh: '编辑盒子' },
		selectItem: { en: 'Select Item', zh: '选择物品' },
		addItem: { en: 'Add Item', zh: '添加物品' },
		editItem: { en: 'Edit Item', zh: '编辑物品' },
		viewItem: { en: 'View Item', zh: '查看物品' },
		orderItem: { en: 'Order', zh: '下单' },
		receiveItem: { en: 'Receive', zh: '入库' },
		rejectItem: { en: 'Reject', zh: '拒绝' },
		personalInformation: { en: 'Personal Information', zh: '个人信息' },
		memberMenage: { en: 'Group Management', zh: '成员管理' },
		changePassword: { en: 'Change Password', zh: '修改密码' },
		imageCheck: {en: 'Image Check',zh:'图片查重'},
		taskName: {en: 'Task Name',zh:'任务名称'},
		taskInfo: {en: 'Task Info',zh:'任务信息'},
		taskCreate: {en: 'Create Task',zh:'创建任务'},
		taskResult: {en: 'Result',zh:'查重结果'},
		title: {en: 'Title',zh:'标题'},
	},
	btnTextMap: {
		login: { en: 'login', zh: '登录' },
		register: { en: 'sign up', zh: '注册' },
		invite: { en: 'invite', zh: '邀请' },
		submit: { en: 'submit', zh: '提交' },
		cancel: { en: 'cancel', zh: '取消' },
		delete: { en: 'delete', zh: '删除' },
		add: { en: 'Add', zh: '添加' },
		addItem: { en: 'add item', zh: '添加物品' },
		select: { en: 'select', zh: '选择' },
		search: { en: 'search', zh: '搜索' },
		reset: { en: 'reset', zh: '重置' },
		confirm: { en: 'confirm', zh: '确定' },
		view: { en: 'view', zh: '查看' },
		handle: { en: 'handle', zh: '操作' },
		ordered: { en: 'ordered', zh: '下单' },
		received: { en: 'received', zh: '入库' },
		batchAdd: { en: 'batch add', zh: '批量添加' },
		batchDelete: { en: 'Delete', zh: '删除' },
		batchDownload: { en: 'Download', zh: '导出' },
		batchSelect: { en: 'Select', zh: '选择' },
		psd: { en: 'forgot password', zh: '忘记密码' },
		logOff: { en: 'log off', zh: '注销' },
		sendCode: { en: 'send code', zh: '发送验证码' },
		downloadTemp: { en: 'download template', zh: '下载模板' },
		changePst: { en: 'change position', zh: '交换位置' },
		cancelExchange: { en: 'cancel exchange', zh: '取消交换' },
		reject: { en: 'reject', zh: '拒绝' },
		read: { en: 'Read', zh: '已读' },
		uploadImage: {en: 'Upload Image',zh:'上传图片'},
		clickToView: {en: 'Please clicking',zh:'点击查看'},
		example: {en: 'Example',zh:'示例'},
	},
}
