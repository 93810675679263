<!--
 * @Author: iKun
 * @Date: 2024-01-09 14:32:49
 * @LastEditors: iKun
 * @LastEditTime: 2024-01-16 16:51:07
 * @Description: 介绍文件的作用
-->
<template>
  <!-- 首页 -->
  <div class="home">
    <!-- 头部 -->
    <home-header
      :language="language"
      @changeLanguage="changeLanguage"
    ></home-header>
    <el-carousel class="banner" v-if="routerPath !=='/home/userInfo'">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <img :src="require('../../assets/images/home/' + item.img)" />
      </el-carousel-item>
    </el-carousel>
    <router-view :language="language"></router-view>
    <!-- 尾部 -->
    <home-footer :language="language" v-if="routerPath !=='/home/userInfo'"></home-footer>
  </div>
</template>

<script>
import { setCookieValue, getCookieValue } from '@/utils/auth'
import standardData from '@/config/standardData'
import HomeHeader from './header.vue'
import HomeFooter from './footer.vue'

export default {
  name: 'home-page',
  components: {
    HomeHeader,
    HomeFooter,
  },
  computed: {
    routerPath() {
      return this.$route.path
    },
  },
  data() {
    return {
      language: 'en',
      slogenTitle: standardData.slogenTitle,
      slogenText: standardData.slogenText,

      bannerList: [
        {
          id: '1',
          img: 'banner.png',
          url: 'http://www.baidu.com',
        },
      ],
    }
  },
  created() {
    const language = getCookieValue('LANGUAGE')
    if (!language) {
      // 获取浏览器语言
      const lang = navigator.language || navigator.userLanguage
      if (lang === 'zh-CN') {
        this.language = 'zh'
      } else {
        this.language = 'en'
      }
      setCookieValue('LANGUAGE', this.language)
    } else {
      this.language = language
    }
    this.setLanguageInputValue()
    const hash = decodeURIComponent(window.location.hash)
    const index = hash.indexOf('?')
    if (index === -1) {
      return
    }
    // this.$router.push(`/register${hash.slice(index)}`)
  },
  methods: {
    changeLanguage(val) {
      this.language = val
      setCookieValue('LANGUAGE', val)
      this.setLanguageInputValue()
    },
    setLanguageInputValue() {
      // 查找language-select下的input元素，手动触发change事件
      this.$nextTick(() => {
        const val = getCookieValue('LANGUAGE')
        // 查找另一个值
        const otherVal = val === 'zh' ? 'English' : '中文'
        const input = document.querySelector('.language-select input')
        if(input) {
          input.value = otherVal
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  background-image: url('~@/assets/images/home/home-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

::v-deep.el-carousel {
  width: 100%;
  height: 706px;
  .el-carousel__container {
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
