/*
 * @Author: iKun
 * @Date: 2023-07-09 21:20:16
 * @LastEditors: iKun
 * @LastEditTime: 2023-11-28 09:19:46
 * @Description: 介绍文件的作用
 */
export default {
	LOGIN: '/api/user/v1/login', // 登录
	REGISTER: '/api/user/v1/register', // 注册
	SEND_CODE: '/api/email/v1', // 发送验证码,
	INVITE_REGISTER: '/api/user/v1/invite', // 邀请注册
	FORGET_PASSWORD: '/api/user/v1/forgotPassword', // 忘记密码
	INIT_LAB: '/api/container/v1/initLab', // 初始化实验室
	GET_TEMPLATE_LIST: '/api/container/v1/listTemp', // 获取实验室默认容器列表
	INIT_CONTAINER: '/api/container/v1/initContainer', // 初始化容器
	ADD_TEMPLATE: '/api/container/v1/addContainerConfig', // 新增容器模板
	EDIT_TEMPLATE: '/api/container/v1/updateContainerConfig', // 修改容器模板
	DELETE_TEMPLATE: '/api/container/v1/delContainerConfig', // 删除容器模板
	GET_FRIG_LIST: '/api/container/v1/list', // 获取冰箱列表
	ADD_CONTAINER: '/api/container/v1/initContainer', // 新增冰箱
	EDIT_CONTAINER: '/api/container/v1/updateContainer', // 修改冰箱
	DELETE_CONTAINERS: '/api/container/v1/delContainers', // 删除容器
	MAIN_NEXT_LEVEL_CONTAINER: '/api/container/v1/addOrUpdateInnerContainer', // 维护下级容器数据
	GET_NEXT_LEVEL_CONTAINER_DATA: '/api/container/v1/nextLevelContainers', // 获取下级容器数据
	GET_COMPANY_DATA: '/api/goods/v1/companies', // 获取公司数据
	GET_SKU_DATA_BY_COMPANY: '/api/goods/v1/skus', // 根据公司名称获取货号
	GET_ITEMS_DATA_BY_LIEK: '/api/goods/v1', // 获取商品数据
	GET_ITEMS_DATA: '/api/goods/v1/findByCompanyAndItemId', // 获取商品数据
	GET_ORDER_LIST: '/api/order/v1/list', // 获取订单列表
	GET_ORDER_LIST_BY_IDS: '/api/order/v1/getOrderList', // 获取订单列表
	ADD_ORDER: '/api/order/v1', // 新增订单
	EDIT_ORDER: '/api/order/v1/update', // 编辑订单
	DELETE_ORDER: '/api/order/v1/delete', // 删除订单
	DOWNLOAD_TEMPLATE: '/api/order/v1/exportOrderModel', // 下载模板
	BATCH_MATCH_ITEMS: '/api/order/v1/matchOrders', // 批量匹配物品
	BATCH_UPLOAD_ITEMS: '/api/order/v1/importOrders', // 批量导入物品
	BATCH_DOWNLOAD_LOCATIONS: '/api/container/v1/export', // 批量导出坐标,
	EXCHANGE_POSITION: '/api/container/v1/exchangeContainers', // 交换位置
	GET_USER_INFO: '/api/user/v1/getUserInfo', // 获取用户信息
	GET_LAB_USERS: '/api/user/v1/getLabUsers', // 获取当前实验室所有成员信息
	UPDATE_ROLE: '/api/user/v1/updateRole', // 修改用户角色
	UPDATE_USER_INFO: '/api/user/v1/updateUserInfo', // 修改用户信息
	GET_MESSAGE: '/api/message/v1/list', // 获取消息列表
	READ_MESSAGE: '/api/message/v1/read', // 读取消息
	KICK_OUT: '/api/user/v1/kickOut', // 踢出用户
	RETRIEVE: '/api/user/v1/retrieve', // 恢复用户
	GET_TASK_LIST: '/api/imgCheckTask/v1/pageList', // 获取图片查重列表
	CREATE_TASK: '/api/imgCheckTask/v1/save', // 创建图片查重任务
	GET_TASK_DETAIL:'/api/imgCheckTask/v1/detail', // 查看图片查重详情
	DOWNLOAD_TASK_RESULT: '/api//imgCheckTask/v1/download', // 下载查重结果
	CREATE_TASK_FREE: '/api/imgCheckTaskFree/v1/save', // 创建免费图片查重任务
	GET_TASK_LIST_FREE: '/api/imgCheckTaskFree/v1/pageList', // 获取免费图片查重列表
	GET_TASK_DETAIL_FREE: '/api/imgCheckTaskFree/v1/detail', // 查看免费图片查重详情
	DOWNLOAD_TASK_RESULT_FREE: '/api/imgCheckTaskFree/v1/download', // 下载免费查重结果

	PERIODICAL_LIST: '/api/journal/v1/getPageList', // 获取期刊列表
	LITERATURE_LIST: '/api/literatureData/v1/getLiteratureData', // 获取文献列表
	GET_LITER_DATA: '/api/literatureData/v1/getPageList', // 获取文献数据
	GET_ARTICAL_NUM:'/api/literatureData/v1/getTotalCount',// 获取文献数量
}
